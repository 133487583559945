export const Colors = {
  darkerGreen: "#131f22",
  lighterGreen: "#17262b",
  backgroundBoxGreen: "#1b2c32",
  beige: "#FEE5B5",
  beigeHover: "#B8860B",
  white: "#FFF",
  lightGrey: "#989898",
  black: "#000",
  transparentBlack: "#0000009a",
};
