import { createContext, useState, FC, ReactNode, useContext } from "react";

type ImagePreviewContextType = {
  selectedImage: string | null;
  setSelectedImage: (image: string | null) => void;
};

export const ImagePreviewContext = createContext<ImagePreviewContextType>({
  selectedImage: null,
  setSelectedImage: () => {},
});

export const useImagePreview = () => useContext(ImagePreviewContext);

export const ImagePreviewProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  return (
    <ImagePreviewContext.Provider value={{ selectedImage, setSelectedImage }}>
      {children}
    </ImagePreviewContext.Provider>
  );
};
