import "aos/dist/aos.css";
import Aos from "aos";
import styled from "styled-components";
import { useEffect, lazy, Suspense } from "react";

import Preloader from "./components/Preloader";

import { useIsMobile } from "./hooks/useIsMobile";
import { ImagePreviewProvider } from "./components/ImagePreviewContext";
import FullScreenImagePreview from "./components/FullScreenImagePreview";

// Lazy load your components
const Footer = lazy(() => import("./components/Footer"));
const HeaderSection = lazy(() => import("./components/HeaderSection"));
const SkillsSection = lazy(() => import("./components/SkillsSection"));
const AboutMeSection = lazy(() => import("./components/AboutMeSection"));
const PortfolioSection = lazy(() => import("./components/PortfolioSection"));
const QualitiesSection = lazy(() => import("./components/QualitiesSection"));
const ExperienceSection = lazy(() => import("./components/ExperienceSection"));
const ScrollToTopButton = lazy(() => import("./components/ScrollToTopButton"));
const DarkCircleBackground = lazy(
  () => import("./components/DarkCircleBackground")
);
const RecommendationsSection = lazy(
  () => import("./components/RecommendationsSection")
);

const App = () => {
  const isMobile = useIsMobile();

  useEffect(() => {
    Aos.init({ duration: 600 });
  }, []);

  return (
    <ImagePreviewProvider>
      <Wrapper>
        <Suspense fallback={<Preloader />}>
          {!isMobile && <DarkCircleBackground />}
          <HeaderSection />
          <AboutMeSection />
          <PortfolioSection />
          <ExperienceSection />
          <RecommendationsSection />
          <QualitiesSection />
          <SkillsSection />
          <ScrollToTopButton />
          <Footer />
        </Suspense>
        <FullScreenImagePreview />
      </Wrapper>
    </ImagePreviewProvider>
  );
};

export default App;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;

  * {
    -webkit-tap-highlight-color: transparent;
  }
`;
