import { styled } from "styled-components";
import { BarLoader } from "react-spinners";

import { Colors } from "../helpers/Colors";

const Preloader = () => (
  <Wrapper data-aos="fade-up" data-aos-once="true">
    <h1>Loading, please wait...</h1>
    <BarLoader color={Colors.beige} />
  </Wrapper>
);

export default Preloader;

const Wrapper = styled.section`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  inset: 0;
  text-align: center;
  gap: 1rem;

  h1 {
    margin: 0;
    color: ${Colors.beige};
    font-size: 1.5rem;
  }
`;
