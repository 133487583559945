import styled from "styled-components";
import { useImagePreview } from "./ImagePreviewContext";
import { useCallback, useEffect } from "react";
import { Colors } from "../helpers/Colors";
import { useIsMobile } from "../hooks/useIsMobile";

const FullScreenImagePreview = () => {
  const { selectedImage, setSelectedImage } = useImagePreview();
  const isMobile = useIsMobile();

  const handleClose = useCallback(() => {
    setSelectedImage(null);
  }, [setSelectedImage]);

  useEffect(() => {
    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        handleClose();
      }
    };

    const handleScroll = () => {
      handleClose();
    };

    document.addEventListener("keydown", handleEscKeyPress);
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("keydown", handleEscKeyPress);
      document.removeEventListener("scroll", handleScroll);
    };
  }, [handleClose]);

  if (!selectedImage) return null;

  return (
    <Overlay onClick={handleClose}>
      <Image src={selectedImage} alt="Full Screen Preview" />
      <InfoText>
        {isMobile
          ? "Tap anywhere to close"
          : "Click anywhere or press ESC to close"}
      </InfoText>
    </Overlay>
  );
};

export default FullScreenImagePreview;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;
`;

const Image = styled.img`
  max-width: 90%;
  max-height: 90%;
  border-radius: 0.25rem;
  border: 0.0625rem solid ${Colors.beige};
`;

const InfoText = styled.div`
  position: absolute;
  bottom: 0.75rem;
  color: ${Colors.beige};
  opacity: 0.8;
  font-size: 0.875rem;
  text-align: center;
  width: 100%;
  pointer-events: none;
`;
